










import { Component, Vue } from "vue-property-decorator";
import Banner from "./Banner.vue";

@Component({
  metaInfo: {
    title: "KAHEV Bursları"
  },
  components: {
    Banner
  }
})
export default class ScholarshipView extends Vue {
  tab = null;
}
